<template>
  <div class="servicemall">
    <div class="servicemall-header">
      <div class="servicemall-header-avatarwrap">
        <img
          src="https://i.loli.net/2021/11/03/bDNUWZFYLO76kjJ.png" alt="" class="servicemall-header-avatarwrap-avatar"
        />
        <div class="servicemall-header-avatarwrap-viptype">
          <img src="~@image/my-vip.png" alt=""  />
        </div>
      </div>
      <div class="servicemall-header-nickname">
        游客
      </div>
      <div class="servicemall-header-card">
        <div class="servicemall-header-card-cardtype">
          <img src="~@image/vipcardbg-n.png" alt="" />
        </div>
      </div>
    </div>
    <div class="servicemall-content">
      <div class="servicemall-content-vipdesc servicemall-content-vipdesc">
        <div class="servicemall-content-vipdesc-interests">
          <div class="servicemall-content-vipdesc-interests-title">
            VIP会员尊享权益
          </div>
          <ul class="servicemall-content-vipdesc-interests-interestsdesc">
            <li
              class="servicemall-content-vipdesc-interests-interestsdesc-item"
              v-for="(item, index) in vipainterestsData"
              :key="index"
            >
              <div
                class="
                  servicemall-content-vipdesc-interests-interestsdesc-item-bgi
                "
              >
                <img :src="item.imgsrc" alt="" />
              </div>
              <div
                class="
                  servicemall-content-vipdesc-interests-interestsdesc-item-content
                "
              >
                {{ item.content }}
              </div>
              <div
                class="
                  servicemall-content-vipdesc-interests-interestsdesc-item-name
                "
              >
                {{ item.name }}
              </div>
            </li>
          </ul>
          <ul class="servicemall-content-vipdesc-interests-opentype">
            <li
              class="servicemall-content-vipdesc-interests-opentype-item"
              v-for="item in vipainterestsDesc"
              :key="item.type"
              :class="[vipSelect == item.type ? 'activevipSelect' : '']"
              @click="handleVip(item.type,item.price)"
            >
              <div
                class="servicemall-content-vipdesc-interests-opentype-item-type"
                :class="[vipSelect == item.type ? 'activevipSelectColor' : '']"
              >
                {{ item.unit }}
              </div>
              <div
                class="
                  servicemall-content-vipdesc-interests-opentype-item-price
                "
                :class="[vipSelect == item.type ? 'activevipSelectColor' : '']"
              >
                ¥ <span>{{ item.price }}</span>
              </div>
              <div
                class="
                  servicemall-content-vipdesc-interests-opentype-item-subdesc
                "
                :class="[vipSelect == item.type ? 'activevipSelectColor' : '']"
              >
                     {{item.subdesc}}
              </div>
              <div
                class="servicemall-content-vipdesc-interests-opentype-item-tag"
                v-if="item.type === 3"
              >
                推荐
              </div>
            </li>
          </ul>

           <div class="inputBox">
            <p>
              <span>推荐码：</span>
              <input type="text" placeholder="选填内容" v-model="vipDiscountCode" ref="vipInput">
            </p>
          </div>

          <div
            class="servicemall-content-vipdesc-interests-openbtn"
            @click="openvip()"
          >
            立即下单
          </div>
        </div>
      </div>
      <div class="servicemall-content-vipdesc servicemall-content-vipdesc2">
        <div class="servicemall-content-vipdesc-interests">
          <div class="servicemall-content-vipdesc-interests-title">
            SVIP会员尊享权益
          </div>
          <ul class="servicemall-content-vipdesc-interests-interestsdesc">
            <li
              class="servicemall-content-vipdesc-interests-interestsdesc-item"
              v-for="(item, index) in svipainterestsData"
              :key="index"
            >
              <div
                class="
                  servicemall-content-vipdesc-interests-interestsdesc-item-bgi
                "
              >
                <img :src="item.imgsrc" alt="" />
              </div>
              <div
                class="
                  servicemall-content-vipdesc-interests-interestsdesc-item-content
                "
              >
                {{ item.content }}
              </div>
              <div
                class="
                  servicemall-content-vipdesc-interests-interestsdesc-item-name
                "
              >
                {{ item.name }}
              </div>
            </li>
          </ul>

          <ul class="servicemall-content-vipdesc-interests-opentype">
            <li
              class="servicemall-content-vipdesc-interests-opentype-item"
              v-for="item in svipainterestsDesc"
              :key="item.type"
              :class="[svipSelect == item.type ? 'activevipSelect' : '']"
              @click="handleSvip(item.type,item.price)"
            >
              <div
                class="servicemall-content-vipdesc-interests-opentype-item-type"
                :class="[svipSelect == item.type ? 'activevipSelectColor' : '']"
              >
                {{ item.unit }}
              </div>
              <div
                class="
                  servicemall-content-vipdesc-interests-opentype-item-price
                "
                :class="[svipSelect == item.type ? 'activevipSelectColor' : '']"
              >
                ¥ <span>{{ item.price }}</span>
              </div>

              <div
                class="
                  servicemall-content-vipdesc-interests-opentype-item-subdesc
                "
                :class="[svipSelect == item.type ? 'activevipSelectColor' : '']"
              >
               {{item.subdesc}}
              </div>
              
              <div
                class="servicemall-content-vipdesc-interests-opentype-item-tag"
                v-if="item.type === 3"
              >
                推荐
              </div>
            </li>
          </ul>

          <div class="inputBox">
            <p>
              <span>推荐码：</span>
              <input type="text" ref="svipInput" placeholder="选填内容" v-model="svipDiscountCode">
            </p>
          </div>

          <div
            class="servicemall-content-vipdesc-interests-openbtn"
            @click="opensvip()"
          >
            立即下单
          </div>
        </div>
      </div>
    </div>
     <div class="lineHeight"  v-show="isiPhone"></div>


    <tool-bar @onChangeFragment="onChangeFragment" v-show="isiPhone"></tool-bar>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ToolBar from "./common/ToolBar.vue";
//
// import qs from "qs";
export default {
  components: { ToolBar },
  name: "WServiceMall",
  data() {
    return {
      // vip权益描述
      vipainterestsData: [
        {
          imgsrc: require("@image/vipdesc-1.png"),
          content: "25G",
          name: "网盘",
        },
        {
          imgsrc: require("@image/vipdesc-2.png"),
          content: "3万字/月",
          name: "字数包",
        },
        {
          imgsrc: require("@image/vipdesc-3.png"),
          content: "200点/月",
          name: "下载券",
        },
      ],
      // vip开通类型描述
      vipainterestsDesc: [
        {
          type: "1",
          price: "12",
          subdesc: "仅￥12.00/月",
          unit:'月卡'
        },
        {
          type: "2",
          price: "8",
          subdesc: "仅￥8.00/月",
          unit:'连续包月卡'
        },
        {
          type: "3",
          price: "98",
          subdesc: "仅￥8.17/月",
          rec: true,
          unit:'年卡'
        },
         {
          type: "7",
          price: "196",
          subdesc: "仅￥8.17/月",
          unit:'两年卡'
        },
         {
          type: "8",
          price: "294",
          subdesc: "仅￥8.17/月",
          unit:'三年卡'
        },
      ],
      // svip 权益描述
      svipainterestsData: [
        {
          imgsrc: require("@image/vipdesc-1.png"),
          content: "50G",
          name: "网盘",
        },
        {
          imgsrc: require("@image/vipdesc-2.png"),
          content: "6万字/月",
          name: "字数包",
        },
        {
          imgsrc: require("@image/vipdesc-3.png"),
          content: "500点/月",
          name: "下载券",
        },
      ],
      // svip开通类型描述
      svipainterestsDesc: [
        {
          type: "4",
          price: "25",
          subdesc: "仅￥25.00/月",
          unit:'月卡'
        },
        {
          type: "5",
          price: "18",
          subdesc: "仅￥18.00/月",
          unit:'连续包月卡'
        },
        {
          type: "6",
          price: "198",
          subdesc: "仅￥16.50/月",
          rec: true,
          unit:'年卡'
        },
          {
          type: "9",
          price: "396",
          subdesc: "仅￥16.50/月",
          unit:'两年卡'
        },
          {
          type: "10",
          price: "594",
          subdesc: "仅￥16.50/月",
          unit:'三年卡'
        },
      ],
      vipSelect: 1,
      svipSelect: 4,
      price : 0,
      userInfo:'',
      vipDiscountCode:'',          //vip推荐码
      svipDiscountCode:'',         //svip推荐码
      scroll:"",
    };
  },
  computed: {
    ...mapState({
      profile: (state) => state.profile,
    }),
    //iPhone
    isiPhone(){
      return /iPhone|iPad/i.test(navigator.userAgent);
    },
  },
  filters: {
    // 时间格式化
    formatDate: function (value, fmt) {
      let getDate = new Date(value);
      let o = {
        "M+": getDate.getMonth() + 1,
        "d+": getDate.getDate(),
        "h+": getDate.getHours(),
        "m+": getDate.getMinutes(),
        "s+": getDate.getSeconds(),
        "q+": Math.floor((getDate.getMonth() + 3) / 3),
        S: getDate.getMilliseconds(),
      };
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          (getDate.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
      }
      for (let k in o) {
        if (new RegExp("(" + k + ")").test(fmt)) {
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length === 1
              ? o[k]
              : ("00" + o[k]).substr(("" + o[k]).length)
          );
        }
      }
      return fmt;
    },
  },

  mounted(){
     window.getToken = this.getToken
   
  },
  created() {
     window.getToken = this.getToken
  },
  methods: {

    getToken:function(res){
        this.userInfo = JSON.parse(res)
        // 保存用户token
        this.$store.commit("Login_succ", this.userInfo)
        // 获取用户的信息
        this.$store.dispatch("GET_PROFILE")
        
        
        this.$router.push({
          name:"ServiceMallDef"
        })
    },

    // 路由的切换
    onChangeFragment: function (componentName) {
      console.log(componentName)
      if(componentName == "Community2"){
          this.$router.push(`/home/${componentName}`);
      }else{
        console.log(componentName)
         this.$router.push({
            name:componentName
          });
      }
    },
    // 选择开通SVIP的类型
    handleSvip(type,p) {
      this.svipSelect = type;
      this.price = p;
    },
    // 选择开通VIP的类型
    handleVip(type,p) {
      this.vipSelect = type;
      this.price = p;
    },
    // 开通会员
    openvip() {
        const obj = {
           action : "buy",
           data : {
             memberId:this.vipSelect,
             discountCode:this.vipDiscountCode
           }
        }
      window.webkit.messageHandlers.sendMessage.postMessage(JSON.stringify(obj))
    },  

    opensvip(){
        const obj = {
           action:"buy",
           data:{
             memberId:this.svipSelect,
             discountCode:this.svipDiscountCode
           }
        }
      window.webkit.messageHandlers.sendMessage.postMessage(JSON.stringify(obj))
    }
  },
};
</script>

<style lang="scss" scoped>
.servicemall {
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column;
  font-family: PingFang SC;

  &-header {
    position: relative;
    height: 262px;
    background-image: url("~@image/small-h-bg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;

    &-avatarwrap {
      position: absolute;
      top: 50px;
      left: 50%;
      width: 58.5px;
      height: 58.5px;
      border-radius: 50%;
      // border: 2px solid #e4b569;
      transform: translateX(-50%);

      &-avatar {
        border: 2px solid #e4b569;
        width: 100%;
        border-radius: 50%;
        height: inherit;
        // object-fit: contain;
      }

      &-viptype {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 17.5px;
        height: 17.5px;
        // z-index: 5;
        img {
          width: 100%;
          height: inherit;
        }
      }
    }

    &-nickname {
      position: absolute;
      width:250px;
      white-space:nowrap;
      text-overflow:ellipsis;
      overflow:hidden;
      left: 50%;
      bottom: 130px;
      font-size: 15px;
      font-weight: bold;
      color: #ffffff;
      transform: translateX(-50%);
    }

    &-card {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 147.2px;

      &-cardtype {
        width: 100%;
        height: 100%;

        img {
          width: 100%;
        }
      }

      &-data {
        position: absolute;
        display:flex;
        right: 45px;
        top: 110px;

        &-content {
          // width:53px;
          height:23px;
          padding: 3px 5px;
          font-size:13px;
          font-weight: bold;
          color: #333333;
          background-color: #e4b569;
          border-radius: 10px;

          &.formatdate {
            font-size: 10px;
            font-weight: bold;
            color: #ffefd1;
            border-radius: 0;
            background-color: transparent;
          }
        }
      }
    }
  }

  &-content {
    width: 100%;

    &-vipdesc {
      // height: 450px;
      // background-color: #b8ac99;
      &-interests {
        padding: 28px 15px;

        &-title {
          position: relative;
          width: 100%;
          height: 18px;
          line-height: 18px;
          font-size: 15px;
          font-weight: bold;
          color: #333333;
          text-indent: 1em;

          &::before {
            position: absolute;
            left: 0;
            display: block;
            content: "";
            width: 5px;
            height: inherit;
            border-radius: 5px;
            background-color: #e4b569;
          }
        }

        // 会员权益描述
        &-interestsdesc {
          display: flex;
          justify-content: space-around;
          margin-top: 21px;

          &-item {
            display: flex;
            justify-content: space-around;
            flex-direction: column;
            align-items: center;

            &-bgi {
              width: 53.5px;
              height: 53.5px;

              img {
                width: 100%;
                height: 100%;
              }
            }

            &-content {
              margin-top: 7px;
              font-size: 15px;
              font-weight: bold;
              color: #333333;
            }

            &-name {
              margin-top: 7px;
              font-size: 13px;
              font-weight: bold;
              color: #999999;
            }

            // &-bgi + div {
            //   margin-top: 11px;
            // }
          }
        }
        .inputBox{
          font-size: 15px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #333333;
          margin-top: 20px;
          p{
            display: flex;
            width: 100%;
            line-height: 42px;
          }
          span{
            display: inline-block;
            width: 70px;
          }
          input{
            width: 260px;
            height: 42px;
            border: 2px solid #F5D693;
            border-radius: 10px;
            padding-left: 15px;
            font-size: 13px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #999999;
          }
        }
        // 开通类型描述
        &-opentype {
          display: flex;
          flex-wrap: wrap;

    

          &-item {
            display: flex;
            justify-content: space-around;
            flex-direction: column;
            align-items: center;
            position: relative;
            width: 105px;
            height: 114px;
            border-radius: 8px;
            border: 1px solid #e4b569;
            margin-top: 20px;
            margin-right: 10px;
            &-type {
              font-size: 15px;
              font-weight: 500;
              color: #d3ad7e;
            }

            &-price {
              font-size: 15px;
              font-weight: 500;
              color: #333333;

              span {
                font-size: 23px;
              }
            }

            &-subdesc {
              font-size: 12px;
              font-weight: 500;
              color: #c0c0c0;
            }

            &-tag {
              position: absolute;
              top: -10px;
              right: -1px;
              padding: 2px 8px;
              font-size: 12px;
              background: linear-gradient(-90deg, #ff8b19, #ff6724);
              border-radius: 0px 6px 0px 6px;
              color: #ffffff;
            }
          }
        }

        // 开通按钮
        &-openbtn {
          width: 295px;
          height: 49px;
          margin: 0 auto;
          margin-top: 20px;
          font-size: 17px;
          line-height: 49px;
          font-weight: bold;
          border-radius: 50px;
          text-align: center;
          background: linear-gradient(to right, #e4b569, #f0d29d);
          color: #fefefe;
        }
      }
    }
  }
}
// 选中会员类型的高亮显示
.activevipSelect {
  background: linear-gradient(-28deg, #e4b569, #f0d29d) !important;
}
.lineHeight{
  height: 60px;
}
// 文字高亮
.activevipSelectColor {
  color: #ffffff !important;
}
.bodyHeight{
  position: fixed;
  height: 100%;
  width: 100%;
  background: transparent;
  z-index: 999;
}
</style>
